export const NAMESPACE_CONSTANTS = {
  NAMESPACE: 'Process Group', // TODO: change it to Process Group
  NAMESPACE_LIST: 'Process Group List',
  DEPLOY_NAMESPACE: 'Deploy Process Group',
  UPGRADE_NAMESPACE: 'Upgrade Process Group',
  NAMESPACE_ID: 'Process Group ID',
  FLOW_NAME: 'Flow Name',
  BUCKET_NAME: 'Bucket Name',
  SELECT_NAMESPACE: 'Select Process Group',
  SELECTED_NAMESPACE: 'Selected Process Group',
  SEARCH_NAMESPACE_FLOW_BUCKET_NAME: 'Search Process Group',
  CONFIGURATION_DETAILS: 'Configuration Details',
  FLOW_DETAILS: 'Flow Details',
  VERSION_CONTROL: 'Version Control',
  CANVAS_POSITION: 'Canvas Position',
  CURRENT_POSITION: 'Current Position',
  CURRENT_VERSION: 'Current Version',
  CURRENT_STATE: 'Current State',
  UPDATED_VERSION: 'Updated Version',
  FLOW_CONTROL: 'Flow Control',
  RUNNING_PROCESSORS: 'Running Processors',
  STOPPED_PROCESSORS: 'Stopped Processors',
  INVALID_PROCESSORS: 'Invalid Processors',
  DISABLED_PROCESSORS: 'Disabled Processors',
  ENABLED_FLOW: 'Enable Flow',
  UPDATING_FLOW: 'Updating Flow',
  DISABLED_FLOW: 'Disable Flow',
  STOPPED_FLOW: 'Stop Flow',
  RUNNING_FLOW: 'Start Flow',
  SENSITIVE_VALUE_SET: 'Sensitive value set',
  EMPTY_STRING_SET: 'Empty string set',
  NO_VALUE_SET: 'No value set',
  PARAMETER_CONTEXT: 'Parameter Context',
  ADD_PARAMETER_CONTEXT: 'Add Parameter Context',
  EDIT_PARAMETER_CONTEXT: 'Edit Parameter Context',
  SET_EMPTY_STRING: 'Set empty string',
  SENSITIVE_VALUE: 'Sensitive value',
  VARIABLES: 'Variables',
  ADD_VARIABLES: 'Add Variables',
  EDIT_VARIABLES: 'Edit Variables',
  PARAMETER_ALREADY_EXISTS: 'Parameter with same name already exists',
  VARIABLE_ALREADY_EXISTS: 'Variable with same name already exists',
  ENTER_PARAMETER: 'Enter Parameter',
  ENTER_VARIABLE: 'Enter Variable',
  ENTER_DESCRIPTION: 'Enter Description',
  SCHEDULE_DEPLOYMENT: 'Deployment Schedule',
  DEPLOY: 'Deploy',
  CONTROLLER_SERVICE: 'Controller Service',
  SCHEDULE_DETAILS: 'Schedule Details',
  NAVIGATE: 'Navigate',
  ZOOM_IN: 'Zoom In',
  ZOOM_OUT: 'Zoom Out',
  FIT: 'Fit',
  LEGENDS: 'Legends',
  SELECT_SCHEDULE_TIME: 'Please select schedule time',
  INCORRECT_SCHEDULE_TIME: 'Selected date and time is invalid',
  PROCESS_GROUP_DETAILS: 'Process Group Details',
  PROPERTY_DELETED: 'Property have been deleted successfully',
  PROPERTY_EDITED: 'Property have been updated successfully',
  PROPERTY_ADDED: 'Property have been added successfully',
  SERVICE_ADDED: 'Service have been added successfully',
  PARAMETER_EDITED: 'Parameter have been updated successfully',
  PARAMETER_ADDED: 'Parameter have been added successfully',
  VARIABLE_EDITED: 'Variable have been updated successfully',
  VARIABLE_ADDED: 'Variable have been added successfully',
};
