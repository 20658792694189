export const USER_CONSTANTS = {
  ADD_USER: 'Add User',
  EDIT_USER: 'Edit User',
  ADD_USER_MODAL: 'Add User Modal',
  EDIT_USER_MODAL: 'Edit User Modal',
  USER_DELETE_MODAL: 'User Delete Modal',
  DELETE_USER: 'Delete User',
  USER_LIST: 'User List',
  SEARCH_USER_PLACEHOLDER: 'Search Name, Username, Email',
  DELETE_USER_WARNING: 'Are you sure you want to delete this user?',
  DELETE_USER_DESCRIPTION: 'It will temporary remove the user',
};
