export const CLUSTER_CONSTANTS = {
  CLUSTER: 'Cluster',
  REGISTRY: 'Registry',
  SELECT_CLUSTER: 'Select Cluster',
  CLUSTER_DETAILS: 'Cluster Details',
  REGISTRY_DETAILS: 'Registry Details',
  CLUSTER_SUMMARY: 'Cluster Summary',
  REGISTRY_SUMMARY: 'Registry Summary',
  CLUSTER_NAME: 'Cluster Name',
  REGISTRY_NAME: 'Registry Name',
  CLUSTER_URL: 'Cluster URL',
  REGISTRY_URL: 'Registry URL',
  ENTER_CLUSTER_NAME: 'Enter your Cluster Name',
  ENTER_REGISTRY_NAME: 'Enter your Registry Name',
  ENTER_NIFI_URL: 'Enter your NiFi URL',
  ENTER_REGISTRY_URL: 'Enter your Registry URL',
  PFX_TEST_FAIL_MESSAGE: arg =>
    `We encountered an issue while testing your ${arg}. Please check if your file is correct`,
  CLUSTER_TEST_FAILED: 'Cluster test failed',
  REGISTRY_TEST_FAILED: 'Registry test failed',
  CREDENTIALS_TESTED_SUCCESSFULLY: 'Credentials tested successfully',
  CREDENTIALS_TEST_FAILED: 'Credentials test failed',
  CREDENTIALS_TEST_FAIL_MESSAGE:
    'We encountered an issue while testing your credentials. Please check if your credentials are correct',
  DELETE_CLUSTER_WARNING: 'Are you sure you want to deactivate this cluster?',
  DELETE_CLUSTER_DESCRIPTION: 'It will permanently remove the cluster',
  CLUSTER_LIST: 'Cluster List',
  ENTER_CREDENTIALS: 'Enter Credentials',
  CLUSTER_TESTED_SUCCESSFULLY: 'Cluster Tested Successfully',
  REGISRTY_TESTED_SUCCESSFULLY: 'Registry Tested Successfully',
  ADD_NEW_CLUSTER: 'Add New Cluster',
  ADD_NEW_REGISTRY: 'Add New Registry',
  ADD_CLUSTER_CERTIFICATE: 'Add Cluster Certificate',
  ADD_REGISTRY_CERTIFICATE: 'Add Registry Certificate',
  PFX_FILE_SIZE: '3.7KB',
  PFX_FILE: 'PFX File',
  PFX_PASSPHRASE: 'PFX Passphrase',
  SELECT_PFX_FILE: 'Select your PFX File',
  ENTER_PFX_PASSPHRASE: 'Enter your PFX Passphrase',
  ADD_CREDENTIALS: 'Add Credentials',
  ADD_CERTIFICATE: 'Add Certificate',
  NIFI_CERTIFICATE: 'NiFi Certificate',
  CLUSTER_CERTIFICATE: 'Cluster Certificate',
  REGISTRY_CERTIFICATE: 'Registry Certificate',
  TEST_CERTIFICATE: 'Test Certificate',
  TEST_CREDENTIALS: 'Test Credentials',
  TEST_VIA_CERTIFICATE: 'Test Via Certificate',
  TEST_VIA_CREDENTIALS: 'Test Via Credentials',
  TEST_CLUSTER: 'Test Cluster',
  TEST_REGISTRY: 'Test Registry',
  DELETE_CLUSTER: 'Delete Cluster',
  SELECTED_CLUSTER: 'Selected Cluster',
  DEACTIVATE_CLUSTER: 'Deactivate Cluster',
  SEARCH_CLUSTER_NAME_URL: 'Search Cluster Name, URL, Tag',
  ENTER_EMAIL: 'Enter your Email',
  ENTER_SMTP_SERVICE: 'Enter SMTP Service',
  ENTER_SMTP_HOST: 'Enter SMTP Host',
  ENTER_SMTP_PORT: 'Enter SMTP Port',
  ENTER_SMTP_USER: 'Enter SMTP User',
  ENTER_SMTP_PASS: 'Enter SMTP Password',
  ENTER_META_TITLE: 'Enter Meta Title',
  CLUSTER_STATUS: 'Cluster Status',
  CLUSTER_ADDED: 'Cluster Added',
  CLUSTER_ADDED_SUCCESSFULLY: 'Cluster Successfully Added',
  CLUSTER_SUCCESS_DESCRIPTION:
    'You can check your cluster in the cluster list.',
  CLUSTER_TESTED_SUCCES_PROMPT:
    'Your cluster test was successful. Continue with the next steps..',
  CLUSTER_TESTED_SUCCES: 'Your cluster test was successful.',
  REGISTRY_TESTED_SUCCESS_PROMPT:
    'Your registry test was successful. Continue with the next steps..',
  HARD_DELETE_CLUSTER_WARNING: 'Are you sure you want to delete this cluster?',
};
