export const ACTIVITY_HISTORY_CONSTANTS = {
  ACTIVITY_HISTORY: 'Activity History',
  ACTIVITY_LIST: 'Activity History List',
  ACTIVITY_HISTORY_SEARCH_PLACEHOLDER: 'Search Messages',
  SELECT_ENTITY: 'Select Entity',
  SELECT_EVENT: 'Select Event',
  SELECT_STATUS: 'Select Status',
  TIMESTAMP: 'Timestamp',
  EVENT: 'Event',
  ENTITY: 'Entity',
  MESSAGE: 'Message',
  CREATE_BY: 'Created By',
  AUDIT_LOG: 'Audit Log',
  CREATED_BY: 'Created By',
  OPEN_AUDIT_LOG: 'Open Audit Log',
};
