import React from 'react';
export const DiffIcon = () => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 9C2 5.13 5.13 2 9 2L7.95 3.75"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.34998 15.45L6.31998 17.7499L10.27 15.46"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.31995 21.82V17.74"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.39 13.21L2.99001 14.54C2.45001 14.84 2 15.5999 2 16.2199V18.76C2 19.38 2.44001 20.14 2.99001 20.44L5.39 21.77C5.9 22.06 6.73999 22.06 7.25999 21.77L9.66 20.44C10.2 20.14 10.65 19.38 10.65 18.76V16.2199C10.65 15.5999 10.21 14.84 9.66 14.54L7.25999 13.21C6.73999 12.93 5.9 12.93 5.39 13.21Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.66 4.94498C22.45 4.81498 22.19 4.80498 21.96 4.91498L19.04 6.37498C18.61 6.58498 18.35 7.01498 18.35 7.49498V10.255C18.35 10.505 18.48 10.735 18.69 10.865C18.81 10.935 18.94 10.975 19.07 10.975C19.18 10.975 19.29 10.945 19.39 10.895L22.31 9.43498C22.74 9.21498 23 8.78498 23 8.31498V5.55498C23 5.30498 22.87 5.07498 22.66 4.94498Z"
        fill="#292D32"
      />
      <path
        d="M16.95 6.37498L14.03 4.91498C13.81 4.80498 13.55 4.81498 13.33 4.94498C13.13 5.07498 13 5.30498 13 5.55498V8.31498C13 8.79498 13.26 9.22498 13.69 9.43498L16.61 10.895C16.71 10.945 16.82 10.975 16.93 10.975C17.06 10.975 17.19 10.935 17.31 10.865C17.52 10.735 17.65 10.505 17.65 10.255V7.49498C17.65 7.01498 17.38 6.58498 16.95 6.37498Z"
        fill="#292D32"
      />
      <path
        d="M21.67 2.825L18.53 1.135C18.2 0.955 17.8 0.955 17.47 1.135L14.33 2.825C14.1 2.945 13.96 3.195 13.96 3.465C13.96 3.735 14.1 3.985 14.33 4.105L17.47 5.795C17.64 5.885 17.82 5.925 18 5.925C18.18 5.925 18.36 5.885 18.53 5.795L21.67 4.105C21.9 3.985 22.04 3.735 22.04 3.465C22.04 3.185 21.89 2.945 21.67 2.825Z"
        fill="#292D32"
      />
    </svg>
  );
};
